<template>
	<div>
		<base-header class="pb-6">
			<div class="row align-items-center py-4">
				<div class="col-lg-6 col-7">
					<h6 class="h2 text-white d-inline-block mb-0"></h6>
				</div>
			</div>
		</base-header>
		<div class="container-fluid mt--6">
			<div class="card">
				<div class="card-header mt--4">
					<div class="row">
						<back></back>
						<div class="col">
							<h3 class="mb-0 text-center" v-if="formType == 'edit' || formType == 'detail'">{{ tt("mcr_form") }}</h3>
							<h3 class="mb-0 text-center" v-if="formType == 'approval'">{{ tt("mcr_approval") }}</h3>
						</div>
					</div>
				</div>
				<div class="card-body">
					<McrAllForm></McrAllForm>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import McrAllForm from "./McrAllForm.vue";

	export default {
		components: {
	      McrAllForm,
	    },
		data() {
			return {
				formType: this.$route.params.type
			}
		}
	};	
</script>